export const useRegisterInvitation = (slug: string) => {
  const invitedCookie = useCookie(`register_${slug}_invitation`, {
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
    sameSite: 'strict',
    secure: true
  })

  const isInvited = computed(() => !!invitedCookie.value)
  const invitationUuid = computed(() => invitedCookie.value || null)

  const setInvitation = (uuid: string) => {
    invitedCookie.value = uuid
  }

  return {
    isInvited,
    invitationUuid,
    setInvitation
  }
}
